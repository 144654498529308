<template>
  <div v-if="!isLoading">
    <b-card class="p-lg-1 mx-5">
      <div class="row justify-content-center align-items-center">
        <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6 mt-3">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon
              v-if="this.orderLabel.payment_status == 'paid'"
              icon="CheckIcon"
              size="400"
              class="text-success"
            />
            <feather-icon
              v-else
              icon="XCircleIcon"
              size="400"
              class="text-warning"
            />
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <h1 v-if="this.orderLabel.payment_status == 'paid'">Thank you !</h1>
            <h1 v-else>Payment Failed !</h1>
          </div>
          <div
            v-if="this.orderLabel.payment_status == 'paid'"
            class="d-flex justify-content-center align-items-center"
          >
            <h3>Order created.</h3>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="gradient-primary"
              @click="generateLabel"
              class="box-shadow-1 w-50"
              >Generate Label</b-button
            >
          </div>
          <div class="d-flex justify-content-center align-items-center my-2">
            <b-button
              v-if="this.orderLabel.payment_status == 'paid'"
              variant="gradient-primary"
              @click="downloadInvoice"
              class="box-shadow-1 w-50"
              >Download Your Invoice Here</b-button
            >
          </div>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-6 col-lg-6">
          <div class="d-flex mt-4">
            <b-card
              class="border w-50 d-flex justify-content-center align-items-center mr-3"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.id }}
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                Order ID
              </h5>
            </b-card>
            <b-card
              class="border w-50 d-flex justify-content-center align-items-center"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                {{ $helpers.formatTotal(orderLabel.amount) }}
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                Amount
              </h5>
            </b-card>
          </div>
          <div class="d-flex">
            <b-card
              class="border w-50 d-flex justify-content-center align-items-center mr-3"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.order_placed_date.substr(0, 10) }}
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                Order Placed
              </h5>
            </b-card>
            <b-card
              class="border w-50 d-flex justify-content-center align-items-center"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.delivery_date.substr(0, 10) }}
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                Delivery Date
              </h5>
            </b-card>
          </div>
          <div class="d-flex">
            <b-card
              class="border w-100 d-flex justify-content-center align-items-center"
            >
              <div>
                <h3 class="d-flex justify-content-center align-items-center">
                  Payment Status
                </h3>
                <div class="d-flex justify-content-center align-items-center">
                  {{
                    this.orderLabel.payment_status == "Paid"
                      ? this.orderLabel.payment_status
                      : this.paymentStatus
                  }}
                </div>
              </div>
              <div
                v-if="this.orderLabel.payment_status != 'Paid'"
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <b-button
                  variant="gradient-primary"
                  class="box-shadow-1"
                  @click="retryPay()"
                  >Click here to try again</b-button
                >
              </div>
            </b-card>
          </div>
          <div class="d-flex">
            <b-card
              class="border w-100 d-flex justify-content-center align-items-center"
            >
              <h3 class="d-flex justify-content-center align-items-center">
                DELIVERY ADDRESS
              </h3>
              <h5 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.recipient.address_unit }},
                {{ orderLabel.recipient.address_line_1 }}
              </h5>
              <h5 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.recipient.address_line_2 }}
              </h5>
              <h5 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.recipient.address_postcode }},
                {{ orderLabel.recipient.address_area }},
              </h5>
              <h5 class="d-flex justify-content-center align-items-center">
                {{ orderLabel.recipient.address_state }},
                {{ orderLabel.recipient.address_country }}
              </h5>
            </b-card>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <b-button
            variant="gradient-primary"
            class="box-shadow-1 px-5"
            href="/singapore-order"
            >Continue Ordering</b-button
          >
        </div>
      </div>
    </b-card>

    <b-modal ref="downloadInvoice" hide-footer hide-title centered size="lg">
      <Invoice :order="orderLabel" />
    </b-modal>

    <b-modal ref="generateLabel" hide-footer hide-title centered size="lg">
      <OrderLabel :order="orderLabel" />
    </b-modal>
  </div>
</template>

<script>
import OrderLabel from "../../Label/OrderLabel.vue";
import Invoice from "@/views/components/Invoice.vue";

export default {
  components: {
    OrderLabel,
    Invoice,
  },
  data() {
    return {
      isLoading: true,
      payment: "",
      paymentStatus: "",
      orderLabel: {},
    };
  },
  mounted() {
    if (this.$route.query.payment_id) {
      this.$http
        .get(`get_order/${this.$route.query.payment_id}`)
        .then((response) => {
          this.isLoading = false;
          if (response.status) {
            this.orderLabel = response.data.data;
          }
          this.checkPayment();
        });
    } else {
      this.$http
        .get(`get_order/${this.$route.params.data}`)
        .then((response) => {
          this.isLoading = false;
          if (response.status) {
            this.orderLabel = response.data.data;
          }
          this.checkPayment();
        });
    }
  },
  methods: {
    checkPayment() {
      this.$http
        .get(`check_order_payment_status/${this.orderLabel.id}`)
        .then((response) => {
          this.payment = response.data.data.status;
          this.paymentStatus = response.data.data.remarks;
        });
    },
    generateLabel() {
      this.$refs["generateLabel"].show();
    },

    downloadInvoice() {
      this.$refs["downloadInvoice"].show();
    },

    continueOrdering() {
      this.$router.replace("/singapore-order");
    },

    retryPay() {
      console.log("test");
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border-width: 3px !important;
}
</style>
